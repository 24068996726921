import './App.css';
import Home from './pages/Home';
import error404 from "./pages/error404";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import ScrollToTop from "./common/ScrollToTop";
import WebsiteDevelopment from "./pages/services/website-development/WebsiteDevelopment"
import SoftwareDevelopment from "./pages/services/software-development/SoftwareDevelopment"
import WebsiteHosting from "./pages/services/website-hosting/WebsiteHosting"
import DigitalMarketing from "./pages/services/digital-marketing/DigitalMarketing"
import ApplicationDevelopment from "./pages/services/application-development/ApplicationDevelopment"
import Designing from "./pages/services/designing/Designing"

import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Header from './common/Header';
import Footer from './common/Footer';
import { useEffect } from 'react';
import PortfolioGallery from './common/PortfolioGallery';

function App() {

  useEffect(() => {
    setTimeout(function () {
      document.getElementById('loading-area').remove();
    }, 1000);
  }, [])


  return (
    <div className="App">
      <div st id="loading-area" className="loading-logo">
        <div className="logo-img">
          <img style={{ width: 150 }}
            src="./assets/images/insanisbrainslogo.svg"
          />
        </div>
        <div style={{ marginTop: -35 }} className="loader-img">
          <img style={{ width: 140 }}
            src="./assets/images/loading-04.svg"
          />
        </div>
      </div>
      <div className="page-wraper">
        <div>
          <Router>
            <Header />
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/our-work" component={PortfolioGallery} />
              <Route path="/contact-us" component={Contact} />
              <Route path="/services/website-development" component={WebsiteDevelopment} />
              <Route path="/services/software-development" component={SoftwareDevelopment} />
              <Route path="/services/hosting" component={WebsiteHosting} />
              <Route path="/services/digital-marketing" component={DigitalMarketing} />
              <Route path="/services/application-development" component={ApplicationDevelopment} />
              <Route path="/services/designing">
                <Redirect to="/contact-us" />
              </Route>
              <Route path="/services">
                <Redirect to="/services/website-development" />
              </Route>
              <Route path="*" component={error404} />
            </Switch>
            <Footer />
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;

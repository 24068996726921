import React, { Component } from 'react';
import * as PortFolioConstants from "../assets/constants/PortFolio";

const portfolio = [
    {
        name: "All",
        category: [
            PortFolioConstants.WEB_DESIGN,
            PortFolioConstants.WEB_DEVELOPMENT,
            PortFolioConstants.BRANDING,
            PortFolioConstants.MOBILE_APP,
            PortFolioConstants.SEO
        ]
    }
]

const WORK_TYPE = {
    BUSINESS_CARD: "Business card",
    LOGO_DESIGN: "Logo design"
}

const portfolio_workd = [
    {
        brandName: "GO MO Group",
        imagePath: "../assets/images/portfolio/Portfolio-Design-30.jpg"
    },
    {
        brandName: "RR Valves",
        imagePath: "../assets/images/portfolio/Portfolio-Design-35.jpg"
    },
    {
        brandName: "Code First Academy",
        imagePath: "../assets/images/portfolio/Portfolio-Design-37.jpg"
    },
    {
        brandName: "Ideal Groups",
        imagePath: "../assets/images/portfolio/Portfolio-Design-36.jpg"
    },
    {
        brandName: "Throttle Store",
        imagePath: "../assets/images/portfolio/Portfolio-Design-33.jpg"
    },
    {
        brandName: "Uzazi",
        imagePath: "../assets/images/portfolio/Portfolio-Design-31.jpg"
    },
    {
        brandName: "YBCI (Youth Business Club India)",
        imagePath: "../assets/images/portfolio/Portfolio-Design-32.jpg"
    },
    {
        brandName: "Webbrawn",
        imagePath: "../assets/images/portfolio/Portfolio-Design-16.jpg"
    },
    {
        brandName: "Webbrawn",
        imagePath: "../assets/images/portfolio/Portfolio-Design-12.jpg"
    },
    {
        brandName: "Cannabis King",
        workType: WORK_TYPE.LOGO_DESIGN,
        imagePath: "../assets/images/portfolio/Portfolio-Design-02.jpg"
    },
    {
        brandName: "Cannabis King",
        workType: WORK_TYPE.BUSINESS_CARD,
        imagePath: "../assets/images/portfolio/Portfolio-Design-01.jpg"
    },
    {
        brandName: "Vyas Associates",
        imagePath: "../assets/images/portfolio/Portfolio-Design-22.jpg"
    },
    {
        brandName: "Vyas Associates",
        imagePath: "../assets/images/portfolio/Portfolio-Design-21.jpg"
    },
    {
        brandName: "Hi Viz (Recruitment & Contracting)",
        imagePath: "../assets/images/portfolio/Portfolio-Design-19.jpg"
    },
    {
        brandName: "Hi Viz (Recruitment & Contracting)",
        imagePath: "../assets/images/portfolio/Portfolio-Design-20.jpg"
    },
    {
        brandName: "Closet Perts",
        imagePath: "../assets/images/portfolio/Portfolio-Design-03.jpg"
    },
    {
        brandName: "Diya Group",
        imagePath: "../assets/images/portfolio/Portfolio-Design-04.jpg"
    },
    {
        brandName: "Help Handy Services",
        imagePath: "../assets/images/portfolio/Portfolio-Design-05.jpg"
    },
    {
        brandName: "ITR (IT Resources)",
        imagePath: "../assets/images/portfolio/Portfolio-Design-06.jpg"
    },
    {
        brandName: "ShortGet.com",
        imagePath: "../assets/images/portfolio/Portfolio-Design-07.jpg"
    },
    {
        brandName: "Tangled Memories",
        imagePath: "../assets/images/portfolio/Portfolio-Design-23.jpg"
    },
    {
        brandName: "SNAP TRAX",
        imagePath: "../assets/images/portfolio/Portfolio-Design-08.jpg"
    },
    {
        brandName: "Surf Shack Radio",
        imagePath: "../assets/images/portfolio/Portfolio-Design-26.jpg"
    },
    {
        brandName: "Tracarta.com",
        imagePath: "../assets/images/portfolio/Portfolio-Design-09.jpg"
    },
    {
        brandName: "XOM Cloud",
        imagePath: "../assets/images/portfolio/Portfolio-Design-10.jpg"
    },
    {
        brandName: "The Palette Cafe",
        imagePath: "../assets/images/portfolio/Portfolio-Design-11.jpg"
    },
    {
        brandName: "Access to a better tomorrow",
        imagePath: "../assets/images/portfolio/Portfolio-Design-13.jpg"
    },
    {
        brandName: "aircia",
        imagePath: "../assets/images/portfolio/Portfolio-Design-14.jpg"
    },
    {
        brandName: "VK Infortech",
        imagePath: "../assets/images/portfolio/Portfolio-Design-17.jpg"
    },
    {
        brandName: "VK Infortech",
        imagePath: "../assets/images/portfolio/Portfolio-Design-18.jpg"
    },
    {
        brandName: "Prostruct",
        imagePath: "../assets/images/portfolio/Portfolio-Design-24.jpg"
    },
    {
        brandName: "Travel Yogis",
        imagePath: "../assets/images/portfolio/Portfolio-Design-27.jpg"
    },
    {
        brandName: "Cargo Buzz",
        imagePath: "../assets/images/portfolio/Portfolio-Design-15.jpg"
    },
    {
        brandName: "Shop to table",
        imagePath: "../assets/images/portfolio/Portfolio-Design-25.jpg"
    },
    {
        brandName: "Comprehencide Care Pharmacy",
        imagePath: "../assets/images/portfolio/Portfolio-Design-28.jpg"
    },
    {
        brandName: "App Design",
        imagePath: "../assets/images/portfolio/Portfolio-Design-29.jpg"
    }
]

class PortfolioGallery extends Component {

    render() {
        return (
            <div className="container portfolio-section">
                {/* <div className="section-head style-1 text-center">
                    <h6 className="sub-title bgl-primary m-b20 text-primary">Projects</h6> 
                    <h2 className="title">Take a look at our latest work </h2>
                </div>  */}
                <div className="row justify-content-md-center">
                    <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                        <div className="section-head style-3 mb-2 text-center">
                            <h2 className="title">Showcasing Excellence in our latest work</h2>
                            <div className="dlab-separator style-2 bg-primary"></div>
                        </div>
                        <p className="text-center">
                            Showcasing Excellence in Web Development and Design
                            Discover a diverse range of web development and design services, crafted by a professional team dedicated to delivering high-quality solutions at competitive prices. Explore our portfolio to see the best features and services we offer.
                        </p>
                    </div>
                </div>
                {/* <div className="site-filters style-1 clearfix center m-b40">
                    <ul className="filters" data-toggle="buttons">
                        <li className="btn active">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">All</a>
                        </li>
                        <li data-filter=".web_design" className="btn">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">Web Design</a>
                        </li>
                        <li data-filter=".web_development" className="btn">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">Web Development</a>
                        </li>
                        <li data-filter=".branding" className="btn">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">Branding</a>
                        </li>
                        <li data-filter=".mobile_app" className="btn">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">Mobile App</a>
                        </li>
                        <li data-filter=".seo" className="btn">
                            <input type="radio"></input>
                            <a href="javascript:void(0);">SEO</a>
                        </li>
                    </ul>
                </div> */}
                <div className="clearfix">
                    <ul id="masonry" className="row lightgallery">
                        {
                            portfolio_workd?.map((work) => (
                                <li className="card-container col-lg-4 col-md-6 col-sm-6 web_design wow fadeInUp"
                                    data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="dlab-box dlab-overlay-box style-1 m-b30">
                                        <div className="dlab-media primary">
                                            <img src={work?.imagePath} alt=""></img>
                                        </div>
                                        {/* <div className="dlab-info">
                                            <h4 className="title"><a href="javascript:void(0);">Webbrawn</a></h4>
                                            <span data-exthumbimage={work?.imagePath}
                                                data-src={work?.imagePath} className="lightimg icon-link">
                                                <i className="fa fa-external-link"></i>
                                            </span>
                                        </div> */}
                                        <div className='m-2 text-center font-weight-bold'>{work?.brandName}</div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default PortfolioGallery;
import React, { Component } from 'react';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import ConnectServiceIcon from "../../../common/ConnectServiceIcon";

class SoftwareDevelopment extends Component {
    render() {
        let page_title = "Software Development";
        let page_subtitle = "Insanis Brain Provides you a Wide Range Of Software Development Service With Professional Team And Affordable Pricing. So Lets have A Looks At our Various Services With Best Features.";
        let website_types = [
            {
                title: "CRM",
                content: "A Customer relationship management (CRM) system helps companies stay connected to customers, streamline processes, and improve profitability."
            },
            {
                title: "ERP",
                content: "Enterprise resource planning (ERP) software standardizes, streamlines and integrates business processes across finance, human resources, procurement, distribution and other dept. "
            },
            {
                title: "Billing Software",
                content: "A billing software can be any software designed to handle time and billing tracking as well as invoicing customers for services and products."
            },
            {
                title: "Restaurant Management Software",
                content: "A restaurant management system is a collective term for software that helps streamline food business operations. Namely, restaurants, bars, bakeries, cafes or delivery."
            },
            {
                title: "Pathalogy/Laboratary Software",
                content: "laboratory management system (LMS), is a software-based solution with features that support a modern laboratory's operations."
            },
            {
                title: "POS Software",
                content: "The point of sale (POS) is the time and place where a retail transaction is completed. Merchant calculates the amount owed by the customer, indicates that amount & much more."
            },
            {
                title: "Accounting Software",
                content: "Software records and processes accounting transactions within functional modules such as accounts payable, accounts receivable, journal, general ledger, payroll, and much more."
            },
            {
                title: "HRM Software",
                content: "Software is designed to automate human resource business processes, transactions, payroll, and compliance. HRM systems allow companies to focus on people management."
            },
            {
                title: "Inventory Management Software",
                content: "Software is required at different locations within a facility / locations of a supply network to precede the regular and planned course of production and stock of materials."
            },
            {
                title: "Garage Management Software",
                content: "A Software is helps to achieve auto shop goals by automating procedures, tracking customer vehicle history, and maintaining a database of other dealers and customers."
            },
            {
                title: "Project Management Software",
                content: "Project management software (PMS) has the capacity to help plan, organize, and manage resource tools and develop resource estimates."
            },
            {
                title: "Task Management Softwares",
                content: "Software is used to manage your tasks, help with the estimation and scheduling, track dependencies, resources and help you make decisions when changes in priority are needed."
            },
            {
                title: "Customized Software",
                content: "Almost any functionality that a client wants can be coded and integrated into the website, Required A Customize Software Development"
            }
        ]

        let website_list = website_types.map(function (type) {
            return <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                <div className="service-box-wrapper style-3 left box-hover wow fadeInUp cursor-pointer m-t30" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="wraper-effect"></div>
                    <div className="icon-content">
                        <h4 className="dlab-title m-b15">{type.title}</h4>
                        <p>{type.content}</p>
                        <ConnectServiceIcon></ConnectServiceIcon>
                    </div>
                </div>
            </div>;
        })

        return (
            <div>
                {/* Website Development section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-3 mb-2 text-center">
                                    <h2 className="title">{page_title}</h2>
                                    <div className="dlab-separator style-2 bg-primary"></div>
                                </div>
                                <p className="text-center">
                                    {page_subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {website_list}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default SoftwareDevelopment;
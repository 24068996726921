import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./common-css/ContactServiceIcon.scss"

class ConnectServiceIcon extends Component {
    render() {
        return (
            <div>
                <p className="mb-2 contact-title">
                    <small>Click Here betow to know More features And Details.</small>
                </p>
                <div className="row connect-wrapper d-flex d-sm-flex d-md-none d-lg-none">
                    <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                        <Link to="/contact-us" className="mr-2 contact-button-link">
                            <div className="contact-button cursor-pointer general-button">
                                <i className="fa fa-money contact-button-icon "></i>
                                <span className="whatsapp-text ml-2">Check Price</span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                        <a href="tel:+918780105205" className="mr-2 contact-button-link">
                            <div className="contact-button cursor-pointer general-button">
                                <i className="fa fa-phone contact-button-icon"></i>
                                <span className="whatsapp-text ml-2">Call Us</span>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4">
                        <a href="https://wa.me/message/AVZ4LX3QYLCHA1" className="mr-2 contact-button-link" target="_blank">
                            <div className="contact-button cursor-pointer whatsapp-button">
                                <i className="fa fa-whatsapp contact-button-icon"></i>
                                <span className="whatsapp-text ml-2">WhatsApp</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="connect-wrapper d-none d-sm-none d-md-flex d-lg-flex align-items-center">
                    <Link to="/contact-us" className="mr-2 contact-button-link">
                        <div className="contact-button cursor-pointer general-button">
                            <i className="fa fa-money contact-button-icon "></i>
                            <span className="whatsapp-text ml-2">Check Price</span>
                        </div>
                    </Link>
                    <a href="tel:+918780105205" className="mr-2 contact-button-link">
                        <div className="contact-button cursor-pointer general-button">
                            <i className="fa fa-phone contact-button-icon"></i>
                            <span className="whatsapp-text ml-2">Call Us</span>
                        </div>
                    </a>
                    <a href="https://wa.me/message/AVZ4LX3QYLCHA1" className="mr-2 contact-button-link" target="_blank">
                        <div className="contact-button cursor-pointer whatsapp-button">
                            <i className="fa fa-whatsapp contact-button-icon"></i>
                            <span className="whatsapp-text ml-2">WhatsApp</span>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default ConnectServiceIcon;
import React, { Component } from 'react';
import Header from "../common/Header";
import Footer from "../common/Footer";

// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

class AboutUs extends Component {
    render() {
        function calculate_age(dob) {
            // Calculate the difference in milliseconds between the current date and the provided date of birth
            var diff_ms = Date.now() - dob.getTime();
            // Create a new Date object representing the difference in milliseconds and store it in the variable age_dt (age Date object)
            var age_dt = new Date(diff_ms);

            // Calculate the absolute value of the difference in years between the age Date object and the year 1970 (UNIX epoch)
            return Math.abs(age_dt.getUTCFullYear() - 1970);
        }

        return (
            <div>
                {/* About us section */}
                <section className="content-inner about-us-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 m-b30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                <div className="dz-media">
                                    <img src="../assets/images/about/img5.png" className="move-3" alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-6 m-b30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-1 mb-4">
                                    {/* <h6 className="sub-title bgl-primary m-b20 text-primary">About Us</h6> */}
                                    <h2 className="title">How We Help You Succeed</h2>
                                </div>
                                <h5>Hello,</h5>
                                <p className="text-justify">We are Insanis Brain, a dynamic team of young enthusiasts dedicated to meeting our clients’ needs in Website Design & Development, E-Commerce Solutions, Branding, Graphic Design, ERP, CRM, CMS, Mobile App Development, SEO, SMO, Digital Marketing, and more.</p>
                                <p className="text-justify">Founded in 2016 in Vadodara, Gujarat, we have now expanded to Pune, Maharashtra. As an emerging IT & Software company, we are committed to driving innovation and making bold, creative ideas a reality. Our core values are Customer Satisfaction, Perfection, and Unity. At Insanis Brain, we dare to turn wild innovation dreams into reality. Join us in our mission to create a smarter, more intelligent, and friendly world.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Counter section */}
                <section className="content-inner bg-primary counter-section-background">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                                <div className="icon-bx-wraper style-4 text-center">
                                    <div className="icon-bx-sm radius bg-green shadow-green m-b20">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-smile"></i>
                                        </a>
                                    </div>
                                    <div className="icon-content">
                                        <span className="title">Satisfied Clients</span>
                                        <h2 className="counter m-b0 text-primary">150+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="icon-bx-wraper style-4 text-center m-tb30">
                                    <div className="icon-bx-sm radius bg-yellow shadow-yellow m-b20">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-line-graph"></i>
                                        </a>
                                    </div>
                                    <div className="icon-content">
                                        <span className="title">Project Completed</span>
                                        <h2 className="counter m-b0 text-primary">140+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                                <div className="icon-bx-wraper style-4 text-center">
                                    <div className="icon-bx-sm radius bg-blue shadow-blue m-b20">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-startup"></i>
                                        </a>
                                    </div>
                                    <div className="icon-content">
                                        <span className="title">Project Launched</span>
                                        <h2 className="counter m-b0 text-primary">180+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                                <div className="icon-bx-wraper style-4 text-center m-tb30">
                                    <div className="icon-bx-sm radius bg-red shadow-red m-b20">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-confetti"></i>
                                        </a>
                                    </div>
                                    <div className="icon-content">
                                        <span className="title">Years Completed</span>
                                        <h2 className="counter m-b0 text-primary">{calculate_age(new Date(2016, 4, 1))}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Feature section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
                                <div className="section-head style-3 mb-4">
                                    <h6 className="sub-title text-primary bgl-primary m-b15">OUR STRATEGY</h6>
                                    <h2 className="title">Success Story Of Insanis Brain</h2>
                                </div>
                                <p>Insanis Brain's Success story or behind the scene story is we are first working on the requirements of our clients and we are adding our innovative ideas and crazy innovative things on that with our experience & client domain analysis.</p>
                                <p>Then we start work on the Design & Development of that product, we have plenty years experienced developers and designers who are making those things.</p>
                                <p className="m-b30">And this story is not yet complete there, the most important thing is Testing, nothing is perfect in the world, but with experience and learning you can reach quality things, and we deliver quality things to our clients.</p>
                            </div>
                            <div className="col-lg-7">
                                <div className="icon-bx-wraper style-3 left box-hover m-r100 m-b10 wow fadeInUp cursor-pointer" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-sm radius">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-idea"></i>
                                        </a>
                                    </div>
                                    <div className="wraper-effect"></div>
                                    <div className="icon-content">
                                        <h4 className="dlab-title m-b15">Idea & Analysis Gathering</h4>
                                        <p>
                                            Without big data analytics, companies are blind and deaf, wandering out onto the Web like deer on a freeway.
                                            <span className="ml-2"><small>- Geoffrey Moore</small></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="icon-bx-wraper style-3 left box-hover m-l100 m-b10 wow fadeInUp cursor-pointer" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="icon-bx-sm radius">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-coding"></i>
                                        </a>
                                    </div>
                                    <div className="wraper-effect"></div>
                                    <div className="icon-content">
                                        <h4 className="dlab-title m-b15">Design & Developing</h4>
                                        <p>
                                            Where you innovate, how you innovate, and what you innovate are design problems.
                                            <br></br><span className="ml-2"><small>- Tim Brown</small></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="icon-bx-wraper style-3 left box-hover m-r100 m-b30 wow fadeInUp cursor-pointer " data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="icon-bx-sm radius">
                                        <a href="#" className="icon-cell">
                                            <i className="flaticon-rocket"></i>
                                        </a>
                                    </div>
                                    <div className="wraper-effect"></div>
                                    <div className="icon-content">
                                        <h4 className="dlab-title m-b15">Testing & Launching</h4>
                                        <p>
                                            Software never was perfect and won’t get perfect. The missing ingredient is our reluctance to quantify quality.
                                            <span className="ml-2"><small>- Boris Beizer</small></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Testimonial Section */}
                {/* <section className="content-inner bg-gray">
                    <div className="px-xl-5 px-md-4 px-3">
                        <div className="row testimonials-wraper-1">
                            <div className="col-lg-9">
                    
                                Owl Carousel for testimonials
                                <div className="testimonials-carousel1">
                                    <OwlCarousel items={2} className="owl-theme" dots="false" nav="false" loop margin={30} >
                                        <div >
                                            <div className="testimonial-1 testimonial-card-no-box-shadow">
                                                <div className="testimonial-text">
                                                    <p>“Aenean massa diam, sollicitudin non dui ut, facilisis viverra libero. Nulla facilisi. Nunc sed purus erat. Praesent placerat dictum libero, eu varius nulla vulputate non”</p>
                                                </div>
                                                <div className="testimonial-detail">
                                                    <div className="testimonial-pic">
                                                        <img src="../assets/images/testimonials/pic3.jpg" alt=""></img>
                                                    </div>
                                                    <div className="clearfix">
                                                        <strong className="testimonial-name">Cak Dikin</strong>
                                                        <span className="testimonial-position">CEO & Founder </span>
                                                        <ul className="star-rating">
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="testimonial-1 testimonial-card-no-box-shadow">
                                                <div className="testimonial-text">
                                                    <p>“Aenean massa diam, sollicitudin non dui ut, facilisis viverra libero. Nulla facilisi. Nunc sed purus erat. Praesent placerat dictum libero, eu varius nulla vulputate non”</p>
                                                </div>
                                                <div className="testimonial-detail">
                                                    <div className="testimonial-pic">
                                                        <img src="../assets/images/testimonials/pic3.jpg" alt=""></img>
                                                    </div>
                                                    <div className="clearfix">
                                                        <strong className="testimonial-name">Lora Price</strong>
                                                        <span className="testimonial-position">CEO & Founder </span>
                                                        <ul className="star-rating">
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="testimonial-1 testimonial-card-no-box-shadow">
                                                <div className="testimonial-text">
                                                    <p>“Aenean massa diam, sollicitudin non dui ut, facilisis viverra libero. Nulla facilisi. Nunc sed purus erat. Praesent placerat dictum libero, eu varius nulla vulputate non”</p>
                                                </div>
                                                <div className="testimonial-detail">
                                                    <div className="testimonial-pic">
                                                        <img src="../assets/images/testimonials/pic3.jpg" alt=""></img>
                                                    </div>
                                                    <div className="clearfix">
                                                        <strong className="testimonial-name">Cak Dikin</strong>
                                                        <span className="testimonial-position">CEO & Founder </span>
                                                        <ul className="star-rating">
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                            <li><i className="fa fa-star text-warning"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="section-head style-1 p-t15">
                                    <h6 className="sub-title bgl-dark m-b20">Testmonial</h6>
                                    <h2 className="title">See What Are They Say About Us </h2>
                                    <p>Fusce vitae sapien eu mauris semper faucibus eget tristique lorem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>
        );
    }
}

export default AboutUs;
import React, { Component } from 'react';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import ConnectServiceIcon from "../../../common/ConnectServiceIcon";

class Designing extends Component {
    render() {
        let page_title = "Hosting";
        let page_subtitle = "Insanis Brain Provides you a variaties of hostings because hosting plays an important role in ensuring website performance, speed, and availability. It’s a technical medium for making your website accessible by anyone on the Internet. hosting is such an essential element for powering anything online, there’s also plenty of choices.";
        let website_types = [
            {
                title: "Website Hosting",
                content: "Web hosting is the place where all the files of your website live. It is like the home of your website where it actually lives. individuals and organizations to make their website accessible."
            },
            {
                title: "Cloud Hosting",
                content: "Cloud computing and cloud hosting, in particular, enables on-demand access to computing resources — CPU, RAM, storage, security services, and moreover the Internet."
            },
            {
                title: "Shared Hosting",
                content: "Shared hosting is an arrangement where several websites are kept on the same server, you will get access to a certain amount of resources that you share with other users"
            },
            {
                title: "Dedicated Hosting",
                content: "Dedicated hosting, as the name implies, gives you exclusive rental rights over a web server. You have full control over the environment and can avoid “noisy neighbors”."
            },
            {
                title: "Reseller Hosting",
                content: "Reseller hosting is the ability to provide hosting to your own clients as if you yourself were the web hosting company. This is typical for aspiring entrepreneurs who want to start their own web hosting firm."
            }
        ]

        let website_list = website_types.map(function (type) {
            return <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                <div className="service-box-wrapper style-3 left box-hover wow fadeInUp cursor-pointer m-t30" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="wraper-effect"></div>
                    <div className="icon-content">
                        <h4 className="dlab-title m-b15">{type.title}</h4>
                        <p>{type.content}</p>
                        <ConnectServiceIcon></ConnectServiceIcon>
                    </div>
                </div>
            </div>;
        })

        return (
            <div>
                {/* Website Development section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-3 mb-2 text-center">
                                    <h2 className="title">{page_title}</h2>
                                    <div className="dlab-separator style-2 bg-primary"></div>
                                </div>
                                <p className="text-center">
                                    {page_subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {website_list}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default Designing;
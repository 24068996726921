import React, { useEffect, useState } from 'react'
import './page-css/Contact.css';
import Utility from '../common/Utility'
import ContactService from '../services/ContactService'

const SERVICES = [
    {
        name: 'Web Development',
        value: 'web_development'
    },
    {
        name: 'Software Development',
        value: 'software_development',
    },
    {
        name: 'Hosting',
        value: 'hosting'
    },
    {
        name: 'SEO & Digital Marketing',
        value: 'digital_marketing'
    },
    {
        name: 'Application Development',
        value: 'application_development'
    },
    {
        name: 'Designing',
        value: 'designing'
    }
]

const PRIORITY = [
    {
        name: 'Low',
        value: 'low'
    },
    {
        name: 'Medium',
        value: 'medium'
    },
    {
        name: 'High',
        value: 'high'
    }
]

const FIELDS_NAME = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    PROJECT_TITLE: 'projectTitle',
    SERVICE: 'service',
    MESSAGE: 'message',
    ESTIMATE_BUDGET: 'estimateBudget',
    PRIORITY: 'priority'
}

const initialFormState = {
    firstName: {
        value: "",
        valid: false,
        required: true,
        errorMessage: "First name required",
    },
    lastName: {
        value: "",
        valid: false,
        required: true,
        errorMessage: "Last name required",
    },
    email: {
        value: "",
        valid: false,
        required: true,
        errorMessage: "Email required",
    },
    phoneNumber: {
        value: "",
        valid: false,
        required: true,
        errorMessage: "Phone number required",
    },
    projectTitle: {
        value: "",
        valid: false,
        required: false,
        errorMessage: "Project titles required",
    },
    service: {
        value: SERVICES[0].value,
        valid: true,
        required: true,
        errorMessage: "Service required",
    },
    message: {
        value: "",
        valid: false,
        required: false,
        errorMessage: ''
    },
    estimateBudget: {
        value: "",
        valid: false,
        required: false,
        errorMessage: "Estimate of budget required",
    },
    priority: {
        value: PRIORITY[0].value,
        valid: true,
        required: false,
        errorMessage: ""
    },
    formSuccess: false
}



const Contact = () => {

    const [formState, setFormState] = useState(Utility.deepClone(initialFormState));


    const onFormChange = (formValue, formField) => {
        let newState = { ...formState }
        switch (formField) {
            case FIELDS_NAME.FIRST_NAME:
                newState.firstName.value = formValue;
                newState.firstName.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.LAST_NAME:
                newState.lastName.value = formValue;
                newState.lastName.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.EMAIL:
                newState.email.value = formValue;
                newState.email.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.PHONE_NUMBER:
                newState.phoneNumber.value = formValue;
                newState.phoneNumber.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.PROJECT_TITLE:
                newState.projectTitle.value = formValue;
                newState.projectTitle.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.SERVICE:
                newState.service.value = formValue;
                newState.service.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.MESSAGE:
                newState.message.value = formValue;
                newState.message.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.ESTIMATE_BUDGET:
                newState.estimateBudget.value = formValue;
                newState.estimateBudget.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            case FIELDS_NAME.PRIORITY:
                newState.priority.value = formValue;
                newState.priority.valid = !Utility.isEmpty(formValue) ? true : false;
                setFormState({ ...newState })
                break;
            default:
                setFormState({ ...newState })
        }
    }

    const formValid = () => {
        let validForm = true;
        Object.keys(formState).forEach((key) => {
            if (formState[key].required && !formState[key].valid) {
                validForm = false;
            }
        })

        return validForm;
    }

    const makePayload = () => {
        let Payload = {
            firstName: formState?.firstName?.value,
            lastName: formState?.lastName?.value,
            email: formState?.email?.value,
            phoneNumber: formState?.phoneNumber?.value,
            projectTitles: formState?.projectTitle?.value,
            services: formState?.service?.value,
            message: formState?.message?.value,
            estimateBudget: formState?.estimateBudget?.value,
            priority: formState?.priority?.value,
        }

        return Payload;
    }

    const onSubmit = async () => {
        if (formValid()) {
            ContactService.saveInquiry(makePayload())
                .then((res) => {
                    let response = res?.data;
                    if (response && response?.status === 'success') {
                        resetForm(true);
                    }
                })
                .catch((error) => {
                    console.log("ERROR", error);
                })
        }
    }

    const resetForm = (showSuccessForm) => {

        let newState = Utility.deepClone(initialFormState)
        if (showSuccessForm) {
            newState.formSuccess = true;
        } else {
            newState.formSuccess = false;
        }
        setFormState({ ...newState })
    }

    return (
        <div>
            <form id="">
                <div className="page-content bg-white">
                    <div className="content-inner contact-section-background">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 m-b30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div className="dzFormMsg"></div>
                                    <input type="hidden" className="form-control" name="dzToDo" value="Contact"></input>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-user"></i></span>
                                                </div>
                                                <input type="text" name="first_name" value={formState.firstName.value} className="form-control" onChange={(e) => {
                                                    onFormChange(e.target.value, FIELDS_NAME.FIRST_NAME)
                                                }} placeholder="First Name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-user"></i></span>
                                                </div>
                                                <input name="last_name" type="text" value={formState.lastName.value} className="form-control"
                                                    placeholder="Last Name" required onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.LAST_NAME)
                                                    }}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-envelope"></i></span>
                                                </div>
                                                <input name="email" type="text" className="form-control" value={formState.email.value} required
                                                    placeholder="Email Address" onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.EMAIL)
                                                    }}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-phone"></i></span>
                                                </div>
                                                <input name="phone" type="text" className="form-control" required
                                                    placeholder="Phone No." value={formState.phoneNumber.value} onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.PHONE_NUMBER)
                                                    }}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-file-alt"></i></span>
                                                </div>
                                                <input name="project_title" type="text" required
                                                    className="form-control" placeholder="Project Title" value={formState.projectTitle.value} onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.PROJECT_TITLE)
                                                    }}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-list"></i></span>
                                                </div>
                                                <select value={formState.service.value} onChange={(e) => {
                                                    onFormChange(e.target.value, FIELDS_NAME.SERVICE)
                                                }} className="form-control" >
                                                    <option selected>Services</option>
                                                    {SERVICES.map((service, index) => <option key={`${`service` - index}`} value={service.value}>{service.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-sms"></i></span>
                                                </div>
                                                <textarea name="message" className="form-control"
                                                    placeholder="Message" value={formState.message.value} onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.MESSAGE)
                                                    }} ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-file-alt"></i></span>
                                                </div>
                                                <input name="budgets" type="number" required
                                                    className="form-control" placeholder="Estimated Buget" value={formState.estimateBudget.value} onChange={(e) => {
                                                        onFormChange(e.target.value, FIELDS_NAME.ESTIMATE_BUDGET)
                                                    }}></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="la la-list"></i></span>
                                                </div>
                                                <select name="priority" value={formState.priority.value} onChange={(e) => {
                                                    onFormChange(e.target.value, FIELDS_NAME.PRIORITY)
                                                }} className="form-control" >
                                                    <option selected>Priority</option>
                                                    {PRIORITY.map((priority) => <option value={priority.value}>{priority.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <button disabled={false} type="button" onClick={() => onSubmit()} className="btn btn-primary gradient border-0 rounded-xl">
                                                Submit
                                            </button>
                                        </div>
                                        {
                                            formState.formSuccess && <div className="col-sm-12">
                                                <div className="form-success mt-3">
                                                    Thanks for sharing your details. <br></br>
                                                    Insanis Brain team will get back to you in working 24 Hours.
                                                    <div onClick={() => {
                                                        let newState = { ...formState };
                                                        newState.formSuccess = false;
                                                        setFormState({ ...newState })
                                                    }} className="clear-message">
                                                        <i className="fa fa-times"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-6 m-b30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div className="dlab-media cf-r-img">
                                        <img src="../assets/images/about/img2.png" className="move-4" alt=""></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
        </div >
    );
}

export default Contact
import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "./common-css/Header.css";

const Header = (props) => {
    const [state, setState] = useState(false);

    const changeValueOnScroll = () => {
        const scrollValue = document.documentElement.scrollTop;
        if (scrollValue > 1) {
            setState(true);
        } else {
            setState(false);
        }
    };

    window.addEventListener("scroll", changeValueOnScroll);

    return (
        <div className={`${state ? "fixed-top bg-white" : "site-header header-transparent"} text-black mo-left`}>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
                <div className="main-bar clearfix ">
                    <div className="container clearfix">
                        {/*  Website Logo */}
                        <div className="logo-header mostion logo-dark">
                            <Link activeClassName="active-menu" to="/">
                                <img src="../assets/images/logo.png" alt=""></img>
                            </Link>
                        </div>
                        {/*  Nav Toggle Button  */}
                        <button
                            className="navbar-toggler collapsed navicon justify-content-end"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                            <div className="logo-header">
                                <NavLink exact to="/">
                                    <img src="../assets/images/logo.png" alt=""></img>
                                </NavLink>
                            </div>
                            <ul className="nav navbar-nav navbar">
                                <li className="active">
                                    <NavLink exact activeClassName="active-menu" to="/">
                                        <span>Home</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active-menu" to="/about-us">
                                        <span>About Us</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active-menu" to="/services">
                                        <span>Services</span>
                                    </NavLink>
                                    <ul className="sub-menu">
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/services/website-development">
                                                Website Development
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/services/software-development">
                                                Software Development
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/services/application-development">
                                                Application Development
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/services/hosting">
                                                Hosting
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/services/digital-marketing">
                                                SEO & Digital Marketing
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active-menu" to="/contact-us">
                                                Designing
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <NavLink activeClassName="active-menu" to="/our-work">
                                        <span>Our Work</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active-menu" to="/contact-us">
                                        <span>Contact Us</span>
                                    </NavLink>
                                </li>
                                <li className="d-none d-lg-block d-xl-block ml-2">
                                    <a href="https://wa.me/message/AVZ4LX3QYLCHA1" className="pl-0" target="_blank" rel="noreferrer">
                                        <div className="contact-whatsapp cursor-pointer">
                                            <i className="fa fa-whatsapp contact-whatsapp-icon ml-0"></i>
                                            <span className="whatsapp-text ml-2">WhatsApp</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                            <div className="social-icon-header d-block d-xs-block d-sm-block d-md-block d-lg-none d-xl-none py-1 px-3">
                                <div className="d-flex">
                                    <div className="my-2">
                                        <a href="https://wa.me/message/AVZ4LX3QYLCHA1" target="_blank" rel="noreferrer">
                                            <div className="contact-whatsapp cursor-pointer">
                                                <i className="fa fa-whatsapp contact-whatsapp-icon"></i>
                                                <span className="whatsapp-text ml-2">WhatsApp</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;

import React, { Component } from 'react';
import Slider1 from '../slider/Slider1';
import { Link } from "react-router-dom";
import './page-css/Home.scss';

class Home extends Component {
    render() {
        return (
            <div>
                <div className="page-content bg-white">
                    <Slider1></Slider1>
                </div>

                {/* Our Service Section */}
                <section id="section1" title="section1" className="content-inner service-section-background">
                    <div className="container">
                        <div className="section-head style-1 text-center">
                            <h6 className="sub-title bgl-primary m-b20 text-primary">Services</h6>
                            <h2 className="title">Provide Awesome Service To You</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.1s">
                                <Link to="/services/website-development">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                <i className="flaticon-office"></i>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">
                                                Website Development
                                            </h4>
                                            <p>Websites promote you 24/7: No employee will do that.<small>― Paul Cookson </small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.3s">
                                <Link to="/services/software-development">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                <i className="flaticon-pie-charts"></i>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">Software Development</h4>
                                            <p>Don't comment bad code - rewrite it. <br></br><small>- Brian Kernighan</small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.6s">
                                <Link to="/services/application-development">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30 application-development-card">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                {/* <i className="flaticon-help"></i> */}
                                                <img className="without-hover" height="43px" src="../assets/images/services/smartphone.png" alt=""></img>
                                                <img className="hover" height="43px" src="../assets/images/services/smartphone-white.png" alt=""></img>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">Application Development</h4>
                                            <p>Your mobile is the easiest portal into your digital self. <small>- Phil Nickinson</small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.4s">
                                <Link to="/services/hosting">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                <i className="flaticon-dollar"></i>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">Hosting</h4>
                                            <p>To make your site hit a new high. <br></br><small>- Margaret Oscar</small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.5s">
                                <Link to="/services/digital-marketing">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                <i className="flaticon-line-graph"></i>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">SEO & Digital Marketing</h4>
                                            <p>Google only loves you when everyone else loves you first. <small>- Wendy Piersall</small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 cursor-pointer" data-wow-duration="2s" data-wow-delay="0.2s">
                                <Link to="/contact-us">
                                    <div className="icon-bx-wraper style-3 box-hover m-b30">
                                        <div className="icon-bx-sm radius bgl-primary">
                                            <a href="#" className="icon-cell">
                                                <i className="flaticon-website"></i>
                                            </a>
                                        </div>
                                        <div className="wraper-effect"></div>
                                        <div className="icon-content">
                                            <h4 className="dlab-title m-b15">Designing</h4>
                                            <p>Make it simple, but significant. <br></br><small>― Alina Wheeler</small></p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }


}

export default Home;
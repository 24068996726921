
export default class Utility {

  static apiEndPoint = () => {
    return `${process.env.REACT_APP_BASE_URL}api/v1/contacts`
  }

  static isEmpty = (value) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (Array.isArray(value) || typeof value === 'string') return !value.length;
    return Object.keys(value).length === 0;
  };

  static isValidEmail(email) {
    if (email.trim() === '') {
      return false;
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
}
import React, { Component } from 'react';
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";

class error404 extends Component {
    render() {
        return (
            <div>
                <div className="section-full background-image-404 pt-5">
                    <div className="container">
                        <div className="error-page text-center">
                            <div className="dlab_error">404</div>
                            <h2 className="error-head">We are sorry. But the page you are looking for cannot be found.</h2>
                            <Link to="/home" className="btn btn-primary radius-no"><span className="p-lr15">Back to Homepage</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default error404;
import React, { Component } from 'react';
import './Footer.css';
import { Link } from "react-router-dom";
// import * as moment from 'moment'
import './../assets/custom/custom.scss';
import { getYear } from 'date-fns/esm';


class Footer extends Component {
    render() {
        return (
            <div className="footer site-footer style-1" id="footer">
                <div className="footer-top">
                    <div className="container">
                        {/* <div className="footer-info" data-wow-duration="2s" data-wow-delay="0.8s">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <div className="footer-links">
                                        <div className="icon-bx-wraper left m-b10">
                                            <div className="icon-content">
                                                <p className="contact-info-wrapper">
                                                    <a href="tel:+918780105205" className="phone-info">
                                                        <span>
                                                            <i className="fa fa-phone mr-2"></i>
                                                        </span>
                                                        <span>
                                                            +91 878-010-5205
                                                        </span>
                                                    </a>
                                                    <br />
                                                    <a href="mailto:contact@insanisbrain.com" className="email-info">
                                                        <span>
                                                            <i className="fa fa-envelope mr-2"></i>
                                                        </span>
                                                        <span>
                                                            contact@insanisbrain.com
                                                        </span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <a href="https://goo.gl/maps/qfTDLGRjVmmS7YEw6" target="_blank" className="footer-links">
                                        <div className="icon-bx-wraper left m-b10">
                                            <div className="icon-lg">
                                                <div className="icon-cell">
                                                    <i className="flaticon-location footer-icon"></i>
                                                </div>
                                            </div>
                                            <div className="icon-content">
                                                <p>B-106, Krishnapark, Ajwa Road, Vadodara, Gujarat</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-6">
                                    <a href="https://goo.gl/maps/qfTDLGRjVmmS7YEw6" target="_blank" className="footer-links">
                                        <div className="icon-bx-wraper left m-b10">
                                            <div className="icon-lg">
                                                <div className="icon-cell">
                                                    <i className="flaticon-location footer-icon"></i>
                                                </div>
                                            </div>
                                            <div className="icon-content">
                                                <p>A-201, Green County-2, Hadapsar, Pune, </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" data-wow-duration="2s" data-wow-delay="0.2s">
                                <div className="widget widget_about">
                                    <h5 className="footer-title">About Us</h5>
                                    <p className="text-justify">We, at Insanis Brain are doing design and development of customized websites, softwares & mobile applications, QA Services, Cloud Solutions.</p>
                                </div>
                                <div className="footer-links">
                                    <div className="icon-bx-wraper left m-b10">
                                        <div className="icon-content">
                                            <p className="contact-info-wrapper">
                                                <a href="tel:+918780105205" className="phone-info">
                                                    <span>
                                                        <i className="fa fa-phone mr-2"></i>
                                                    </span>
                                                    <span>
                                                        +91 878-010-5205
                                                    </span>
                                                </a>
                                                <br />
                                                <a href="mailto:contact@insanisbrain.com" className="email-info">
                                                    <span>
                                                        <i className="fa fa-envelope mr-2"></i>
                                                    </span>
                                                    <span>
                                                        contact@insanisbrain.com
                                                    </span>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-12" data-wow-duration="2s" data-wow-delay="0.4s">
                                <div className="widget widget_services my-4">
                                    <h5 className="footer-title">Our links</h5>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/our-work">Our Work</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div className="widget widget_services my-4">
                                    <h5 className="footer-title">Our Services</h5>
                                    <ul>
                                        <li>
                                            <Link to="/services/website-development">
                                                Website Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/software-development">
                                                Software Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/hosting">
                                                Hosting
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" data-wow-duration="2s" data-wow-delay="0.8s">
                                <div className="widget widget_services my-4">
                                    <h5 className="footer-title-no-heading d-none d-md-block d-lg-block d-xl-block">&nbsp;</h5>
                                    <ul>
                                        <li>
                                            <Link to="/services/digital-marketing">
                                                SEO & Digital Marketing
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/application-development">
                                                Application Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services/designing">
                                                Designing
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="container">
                        <div className="footer-top-space">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center d-flex align-items-center">
                                    <div className="dlab-social-icon-footer my-3">
                                        <ul>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-facebook" href="https://www.facebook.com/insanisbrainofficial"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-instagram" href="https://www.instagram.com/insanisbrain/"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-twitter" href="https://twitter.com/insanisbrain"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-linkedin" href="https://www.linkedin.com/company/insanisbrain/"></a></li>
                                        </ul>
                                    </div>
                                    {/* <span className="copyright-text">Copyright © {getYear(new Date())}
                                        <a href="http://insanisbrain.com" target="_blank">Insanis Brain</a>
                                        . All rights reserved.
                                    </span> */}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-md-end">
                                    {/* <div className="dlab-social-icon">
                                        <ul>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-facebook" href="https://www.facebook.com/insanisbrainofficial"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-instagram" href="https://www.instagram.com/insanisbrain/"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-twitter" href="https://twitter.com/insanisbrain"></a></li>
                                            <li><a target="_blank" rel="noreferrer" className="fa fa-linkedin" href="https://www.linkedin.com/company/insanisbrain/"></a></li>
                                        </ul>
                                    </div> */}
                                    <span className="copyright-text">Copyright © 2014 - {getYear(new Date())} {""}
                                        <a href="http://insanisbrain.com" target="_blank">Insanis Brain</a>
                                        . All rights reserved.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
import React, { Component } from 'react';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import ConnectServiceIcon from "../../../common/ConnectServiceIcon";

class ApplicationDevelopment extends Component {
    render() {
        let page_title = "Application Development";
        let page_subtitle = "Insanis Brain Provides you Mobile app development, Which is the act or process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones.";
        let website_types = [
            {
                title: "Android Application Development",
                content: "Android software development is the process by which applications are created for devices running the Android operating system."
            },
            {
                title: "iOS Application Development",
                content: "iOS (formerly iPhone OS) is a mobile operating system created and developed by Apple Inc. exclusively for its hardware. World's second-most installed mobile OS, after Android."
            },
            {
                title: "Hybrid Application Development",
                content: "Hybrid software application that combines elements of both native apps and web applications. Hybrid apps are essentially web apps that have been put in a native app shell."
            },
            {
                title: "Flutter Application Development",
                content: "Flutter is Google's UI toolkit for building beautiful, natively compiled applications for mobile, web, and desktop from a single codebase."
            }
        ]

        let website_list = website_types.map(function (type) {
            return <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                <div className="service-box-wrapper style-3 left box-hover wow fadeInUp cursor-pointer m-t30" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="wraper-effect"></div>
                    <div className="icon-content">
                        <h4 className="dlab-title m-b15">{type.title}</h4>
                        <p>{type.content}</p>
                        <ConnectServiceIcon></ConnectServiceIcon>
                    </div>
                </div>
            </div>;
        })

        return (
            <div>
                {/* Website Development section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-3 mb-2 text-center">
                                    <h2 className="title">{page_title}</h2>
                                    <div className="dlab-separator style-2 bg-primary"></div>
                                </div>
                                <p className="text-center">
                                    {page_subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {website_list}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default ApplicationDevelopment;
import React, { Component } from 'react';
import './Slider1.css';

class Slider1 extends Component {
    render() {
        return (
            <div className="banner-one">
                <div className="container">
                    <div className="banner-inner">
                        <div className="img1"><img src="../assets/images/main-slider/slider1/pic3.png" alt=""></img></div>
                        <div className="img2"><img src="../assets/images/main-slider/slider1/pic4.png" alt=""></img></div>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="banner-content">
                                    <h1 data-wow-duration="0.5s" data-wow-delay="0.5s" className="wow fadeInUp mb-0">Build
                                        Stunning
                                        <span className="text-primary"> Websites, Mobile Applications, Custom Software, Hosting and AI Solutions </span>With us.
                                    </h1>
                                    <p data-wow-duration="0.4s" data-wow-delay="0.5s" className="wow fadeInUp m-b30 m-t30">At Insanis Brain, we don’t just develop software, we craft digital experiences. From bespoke websites and mobile apps to
                                        robust QA services and cutting-edge cloud and AI solutions, we turn your vision into reality.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="dz-media move-box wow fadeIn" data-wow-duration="1.6s"
                                    data-wow-delay="0.8s">
                                    <img className="move-1" src="../assets/images/move/pic1.png" alt="" />
                                    <img className="move-2" src="../assets/images/move/pic2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider1;
import React, { Component } from 'react';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import ConnectServiceIcon from "../../../common/ConnectServiceIcon";

class DigitalMarketing extends Component {
    render() {
        let page_title = "SEO & Digital Marketing";
        let page_subtitle = "Insanis Brain provides you a Digital marketing, which is the component of marketing that utilizes internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services.";
        let website_types = [
            {
                title: "SEO (Search Engine Optimization)",
                content: "Search engine optimization (SEO) is the process of improving the quality and quantity of website traffic to a website or a web page from search engines."
            },
            {
                title: "SMM (Social Media Marketing)",
                content: "Social media marketing is the use of social media platforms and websites to promote a product or service. E-marketing and digital marketing are still dominant in academia."
            },
            {
                title: "Email Marketing",
                content: "Email marketing is the act of sending a commercial message, to a group of people, using email. Every email sent to a potential or current customer via email marketing."
            },
            {
                title: "Content Marketing",
                content: "Content marketing is a form of marketing focused on creating, publishing, and distributing content for a targeted audience online. It's used by businesses in order to achieve goals."
            },
            {
                title: "Affiliate Marketing",
                content: "Affiliate marketing is a type of performance-based marketing in which a business rewards one or more affiliates for each visitor or customer brought by the affiliate's own marketing efforts."
            }
        ]

        let website_list = website_types.map(function (type) {
            return <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                <div className="service-box-wrapper style-3 left box-hover wow fadeInUp cursor-pointer m-t30" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="wraper-effect"></div>
                    <div className="icon-content">
                        <h4 className="dlab-title m-b15">{type.title}</h4>
                        <p>{type.content}</p>
                        <ConnectServiceIcon></ConnectServiceIcon>
                    </div>
                </div>
            </div>;
        })

        return (
            <div>
                {/* Website Development section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-3 mb-2 text-center">
                                    <h2 className="title">{page_title}</h2>
                                    <div className="dlab-separator style-2 bg-primary"></div>
                                </div>
                                <p className="text-center">
                                    {page_subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {website_list}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


export default DigitalMarketing;
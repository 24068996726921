import React, { Component } from 'react';
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import ConnectServiceIcon from "../../../common/ConnectServiceIcon";

class WebsiteDevelopment extends Component {

    render() {
        let page_title = "Website Development";
        let page_subtitle = "Insanis Brain Provides you a Wide Range Of Website Design & Development Service With Professional Team And Affordable Pricing. So Lets have A Looks At our Various Services With Best Features.";
        let website_types = [
            {
                title: "Static Website Development",
                content: "Get a Simple Static Website For Your Business Just only To Show Information Without Any Complex Functionality To Take your Business Online."
            },
            {
                title: "Dynamic Website Development",
                content: "Get a Dynamic Website Development For Your business With Modern Design and complex Functions To Take your Business Online."
            },
            {
                title: "E-Commerce Website Development",
                content: "Get A Single Vendor or Multivendor Ecommerce Website For Your Online Shopping Business And Start Selling With Your New brand ecommerce Website"
            },
            {
                title: "One Page Website Development",
                content: "Get A One-Page Website Development for your business With A Simple And fantastic Design And a Responsive One Page Website."
            },
            {
                title: "Corporate Website Development",
                content: "Do you Have a company, or a Multi National Brand. Get a Corporate website Development With all Corporate functionality"
            },
            {
                title: "CMS Website Development",
                content: "If you need any website for sharing information on daily basis and don't need to be a very bother about technicality to change content, then go with out CMS based websites."
            },
            {
                title: "Customized Website Development",
                content: "Almost any functionality that a client wants can be coded and integrated into the website, android application and CMS, Required A Customize Website Development"
            }
        ]

        let website_list = website_types.map(function (type) {
            return <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                <div className="service-box-wrapper style-3 left box-hover wow fadeInUp cursor-pointer m-t30" data-wow-duration="2s" data-wow-delay="0.2s">
                    <div className="wraper-effect"></div>
                    <div className="icon-content">
                        <h4 className="dlab-title m-b15">{type.title}</h4>
                        <p>{type.content}</p>
                        <ConnectServiceIcon></ConnectServiceIcon>
                    </div>
                </div>
            </div>;
        })

        return (
            <div>
                {/* Website Development section */}
                <section className="content-inner">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-10 m-b10 m-t30 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="section-head style-3 mb-2 text-center">
                                    <h2 className="title">{page_title}</h2>
                                    <div className="dlab-separator style-2 bg-primary"></div>
                                </div>
                                <p className="text-center">
                                    {page_subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {website_list}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default WebsiteDevelopment;